export const positionImage = () => ({
    maxWidth: '43.75rem',
    overflow: 'visible',
    position: 'absolute',
    right: 0,
    zIndex: -2,
    minWidth: '48.0303%',

    tabletLarge: {
        position: 'relative',
    },
});
