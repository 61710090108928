export const container = () => ({
    display: 'flex',
    marginBottom: '1.5rem',
});

export const extendText = {
    text: () => ({
        fontWeight: 900,
    }),
};

export const extendCompanyText = {
    text: ({ theme: { colors } }) => ({
        fontWeight: 500,
        fontStyle: 'italic',
        color: colors.darkGrey,
    }),
};
