import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { sanitize } from 'dompurify';

import * as UI from '../../../../ui';

import TeamLeader from './TeamLeader';

const { DynamicContainer, ContainerType, Title, TitleTypes } = UI;

const JobDescription = ({ styles, rules, content, heroRef, teamLeader, heroHeightState, setHeroHeightState }) => {
    const createMarkup = () => ({
        __html: sanitize(content, { FORBID_ATTR: ['style'] }),
    });

    const [firstParagraphHeight, setFirstParagraphHeight] = React.useState(heroHeightState);

    // handle responsivity
    React.useEffect(() => {
        function onWindowResize() {
            const newRect = heroRef.current.getBoundingClientRect();
            const newHeroHeight = newRect.height;
            setHeroHeightState(newHeroHeight);
            setFirstParagraphHeight(document.querySelector('#dangerous-content').children[0].offsetHeight);
        }

        window.addEventListener('resize', onWindowResize);

        setFirstParagraphHeight(document.querySelector('#dangerous-content').children[0].offsetHeight);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, [heroRef, setHeroHeightState]);

    return (
        <DynamicContainer
            extend={rules.extendMainContainer}
            type={ContainerType.GRID}
            as={ContainerType.SECTION}
            isFluid
        >
            <DynamicContainer heroHeight={heroHeightState} extend={rules.extendContentContainer} columns={6}>
                <div id="dangerous-content" className={styles.container} dangerouslySetInnerHTML={createMarkup()} />
            </DynamicContainer>
            <DynamicContainer
                firstParagraphHeight={firstParagraphHeight}
                heroHeight={heroHeightState}
                extend={rules.extendTeamLeaderContainer}
                columns={3}
                startAt={8}
            >
                {teamLeader ? (
                    <div className={styles.tlContentContainer}>
                        <Title extend={rules.extendTitle} type={TitleTypes.H4}>
                            <FormattedMessage id="jobPage.teamLeader.title" />
                        </Title>
                        <TeamLeader content={teamLeader} />
                    </div>
                ) : null}
            </DynamicContainer>
        </DynamicContainer>
    );
};

JobDescription.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    content: PropTypes.string.isRequired,
    heroRef: PropTypes.shape().isRequired,
    teamLeader: PropTypes.shape().isRequired,
};

export default JobDescription;
