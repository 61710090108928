import loadable from '@loadable/component';
import React from 'react';
import { PropTypes } from 'prop-types';

import { useIntl } from 'react-intl';

import { Position, positions } from 'config/positions';

import * as UI from '../../../ui';
import { useFetchPosition } from '../../hooks';
import JobDescription from './JobDescription';
import HeroSection from './HeroSection';
import { TechnologiesSection } from './TechnologiesSection';
import { InactiveLabel } from 'modules/career-page/components/PositionsSection/InactiveLabel';
import * as felaRules from './JobPage.styles';

import { useFelaEnhanced } from '@ackee/fela';

const FormSection = loadable(() => import('./FormSection'));

const { Loader, ErrorCard, DynamicContainer } = UI;

const JobPage = ({ rules, content, positionId, positionName }) => {
    const { inProgress, success, error, positionContent } = useFetchPosition(positionId);
    const { styles } = useFelaEnhanced(felaRules);

    const isActive = positions[positionName].active && positions[positionName].displayed;

    const { locale } = useIntl();

    const heroRef = React.useRef();
    const [heroHeightState, setHeroHeightState] = React.useState(0);

    React.useLayoutEffect(() => {
        const rect = heroRef.current.getBoundingClientRect();
        const heroHeight = rect.height;
        setHeroHeightState(heroHeight);
    }, [heroRef]);

    return (
        <>
            <HeroSection reference={heroRef} title={content.title} image={content.image} />
            {!isActive && <div className={styles.inactiveLabel} > <InactiveLabel messageId="careerPage.positions.empty" /></div>}
            {positionId !== positions[Position.NOT_FOUND].apiId[locale] && (
                <ErrorCard error={error}>
                    <DynamicContainer extend={rules.extendLoaderContainer} columns={12}>
                        <Loader show={inProgress || !success} heroHeight={heroHeightState} extend={rules.extendLoader}>
                            <JobDescription
                                heroRef={heroRef}
                                teamLeader={content.teamLeader}
                                content={positionContent}
                                heroHeightState={heroHeightState}
                                setHeroHeightState={setHeroHeightState}
                            />
                        </Loader>
                    </DynamicContainer>
                </ErrorCard>
            )}
            {Boolean(content.technologies) ? (
                <TechnologiesSection technologies={content.technologies.content} title={content.technologies.title} />
            ) : null}
            <FormSection
                submitButtonEventId={`submit-career-${positionName}`}
                formEventId={`contact-form-career-${positionName}`}
                locale={locale}
                title={content.formTitle}
                positionId={positionId}
            />
        </>
    );
};

JobPage.propTypes = {
    rules: PropTypes.shape().isRequired,
    content: PropTypes.shape().isRequired,
    positionId: PropTypes.string.isRequired,
};

export default JobPage;
