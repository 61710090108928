export const authorImage = () => ({
    minWidth: '4.875rem',
    height: '4.875rem',
    overflow: 'hidden',
    marginRight: '1.125rem',

    tabletLarge: {
        marginRight: '2.25rem',
    },
});
