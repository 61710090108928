export const extendLoaderContainer = {
    container: () => ({
        minHeight: 300,
    }),
};

export const extendLoader = {
    loader: ({ heroHeight }) => ({
        position: 'relative',
        marginTop: heroHeight / 2 + 60,
        width: '100%',

        tabletLarge: {
            marginTop: heroHeight - 170,
        },

        desktopLarge: {
            marginTop: heroHeight / 2 + 60,
            width: '50%',
        },
    }),
};

export const inactiveLabel = () => ({
    position: 'absolute',
    right: 0,
    top: 0,
    tabletLarge: {
        right: '20%',
        top: '10rem',
    },
    desktopLarge : {
        right: '39%',
        top: '18rem',
    },
});
