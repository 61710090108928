import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../../ui';
import { FormattedMessage } from 'react-intl';

import Image from './Image';

const { Text, TextTypes } = UI;

const TeamLeader = ({ styles, rules, content }) => (
    <>
        <div className={styles.container}>
            <Image alt={content.name} src={content.image} />
            <div className={styles.nameContainer}>
                <Text type={TextTypes.LARGE} extend={rules.extendText}>
                    <FormattedMessage id={content.name} />
                </Text>
                <Text extend={rules.extendCompanyText}>
                    <FormattedMessage id={content.role} />
                </Text>
            </div>
        </div>

        <Text type={TextTypes.LARGE}>
            <FormattedMessage id={content.description} />
        </Text>
    </>
);

TeamLeader.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    content: PropTypes.shape().isRequired,
};

export default TeamLeader;
