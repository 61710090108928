import type { MessageDescriptor } from 'react-intl';

import { Position } from 'config/positions';
import type { TechnologyCategory } from 'modules/ui';
import { content as technologies } from 'modules/career-page/components/TechnologiesSection/content';

interface PositionContent {
    title: MessageDescriptor['id'];
    formTitle: MessageDescriptor['id'];
    image: string;
    teamLeader?: {
        name: MessageDescriptor['id'];
        role: MessageDescriptor['id'];
        description: MessageDescriptor['id'];
        image: string;
    };
    technologies: {
        title: MessageDescriptor['id'];
        content: TechnologyCategory;
    } | null;
}

type AllPositionsContent = {
    [key in Position]: PositionContent;
};

export const content: AllPositionsContent = {
    [Position.ANDROID]: {
        title: 'jobPage.android.hero.title',
        formTitle: 'jobPage.android.form.title',
        image: 'career/positions/detail/android.jpg',
        teamLeader: {
            name: 'jobPage.android.teamLeader.name',
            role: 'jobPage.android.teamLeader.role',
            description: 'jobPage.android.teamLeader.description',
            image: 'people/img_person_pesek.jpg',
        },
        technologies: {
            title: 'jobPage.android.technologies.title',
            content: technologies.android,
        },
    },
    [Position.BACKEND]: {
        title: 'jobPage.backEnd.hero.title',
        formTitle: 'jobPage.backEnd.form.title',
        image: 'career/positions/detail/backend.jpg',
        teamLeader: {
            name: 'jobPage.backEnd.teamLeader.name',
            role: 'jobPage.backEnd.teamLeader.role',
            description: 'jobPage.backEnd.teamLeader.description',
            image: 'people/img_person_svagr.jpg',
        },
        technologies: {
            title: 'jobPage.backEnd.technologies.title',
            content: technologies.backend,
        },
    },
    [Position.FRONTEND]: {
        title: 'jobPage.frontend.hero.title',
        formTitle: 'jobPage.frontend.form.title',
        image: 'career/positions/detail/frontend.jpg',
        teamLeader: {
            name: 'jobPage.frontend.teamLeader.name',
            role: 'jobPage.frontend.teamLeader.role',
            description: 'jobPage.frontend.teamLeader.description',
            image: 'people/img_person_baierl.jpg',
        },
        technologies: {
            title: 'jobPage.frontend.technologies.title',
            content: technologies.web,
        },
    },
    [Position.IOS]: {
        title: 'jobPage.ios.hero.title',
        formTitle: 'jobPage.ios.form.title',
        image: 'career/positions/detail/ios.jpg',
        teamLeader: {
            name: 'jobPage.ios.teamLeader.name',
            role: 'jobPage.ios.teamLeader.role',
            description: 'jobPage.ios.teamLeader.description',
            image: 'people/img_person_rosocha.jpg',
        },
        technologies: {
            title: 'jobPage.ios.technologies.title',
            content: technologies.ios,
        },
    },
    [Position.PROJECT_MANAGER]: {
        title: 'jobPage.projectManager.hero.title',
        formTitle: 'jobPage.projectManager.form.title',
        image: 'career/positions/detail/pm.jpg',
        teamLeader: {
            name: 'jobPage.projectManager.teamLeader.name',
            role: 'jobPage.projectManager.teamLeader.role',
            description: 'jobPage.projectManager.teamLeader.description',
            image: 'people/img_person_sonsky.jpg',
        },
        technologies: null,
    },
    [Position.APP_TESTER]: {
        title: 'jobPage.appTester.hero.title',
        formTitle: 'jobPage.appTester.form.title',
        image: 'career/positions/detail/tester.jpg',
        teamLeader: {
            name: 'jobPage.appTester.teamLeader.name',
            role: 'jobPage.appTester.teamLeader.role',
            description: 'jobPage.appTester.teamLeader.description',
            image: 'people/img_person_zmejevskaya.jpg',
        },
        technologies: {
            title: 'jobPage.appTester.technologies.title',
            content: technologies.testing,
        },
    },
    [Position.DESIGNER]: {
        title: 'jobPage.designer.hero.title',
        formTitle: 'jobPage.designer.form.title',
        image: 'career/positions/detail/design.jpg',
        teamLeader: {
            name: 'jobPage.designer.teamLeader.name',
            role: 'jobPage.designer.teamLeader.role',
            description: 'jobPage.designer.teamLeader.description',
            image: 'people/img_person_mescheryakova.jpg',
        },
        technologies: {
            title: 'jobPage.designer.technologies.title',
            content: technologies.design,
        },
    },
    [Position.INTERN]: {
        title: 'jobPage.intern.hero.title',
        formTitle: 'jobPage.intern.form.title',
        image: 'career/positions/detail/trainee.jpg',
        teamLeader: {
            name: 'jobPage.intern.teamLeader.name',
            role: 'jobPage.intern.teamLeader.role',
            description: 'jobPage.intern.teamLeader.description',
            image: 'people/img_person_chmurova.jpg',
        },
        technologies: null,
    },
    [Position.NOT_FOUND]: {
        title: 'jobPage.notFound.hero.title',
        formTitle: 'jobPage.notFound.form.title',
        image: 'career/positions/detail/unknown.jpg',
        technologies: null,
    },
};
