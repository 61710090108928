export const extendMainContainer = () => ({
    container: ({ theme: { dimensions } }) => ({
        position: 'absolute',
        zIndex: -1,
        left: '50%',
        transform: 'translateX(-50%)',
        tabletLarge: {
            paddingTop: dimensions.headerTabletHeight,
            paddingBottom: 0,
            justifyContent: 'space-between',
        },
    }),
});

export const container = () => ({
    marginTop: '7.5rem',
});

export const title = () => ({
    marginTop: '2.3125rem',
});
