import React from 'react';
import { compose } from 'recompose';
import { injectIntl, useIntl } from 'react-intl';

import { PropTypes } from 'prop-types';

import { Layout } from 'modules/ui';
import { JobPage, content } from 'modules/job-page';
import { positions } from 'config/positions';
import Seo from 'components/SEO';

const PositionPage = ({ pageContext }) => {
    const { positionId } = pageContext;

    const { locale } = useIntl();

    return (
        <>
            <Seo pageId={positionId} />
            <Layout>
                <JobPage
                    content={content[positionId]}
                    positionName={positionId}
                    positionId={positions[positionId].apiId[locale]}
                />
            </Layout>
        </>
    );
};

PositionPage.propTypes = {
    pageContext: PropTypes.shape({
        positionId: PropTypes.string.isRequired,
    }).isRequired,
};

export default compose(injectIntl)(PositionPage);
