import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';

import Image from './Image';

const { DynamicContainer, ContainerType, Title, Text, TextTypes, TitleTypes } = UI;

const HeroSection = ({ rules, styles, image, title, reference }) => (
    <DynamicContainer
        extend={rules.extendMainContainer}
        isFluid
        as={ContainerType.SECTION}
        type={ContainerType.FLEX}
        ref={reference}
    >
        <div className={styles.container}>
            <Text type={TextTypes.SECTION}>
                <FormattedMessage id="jobPage.sectiontitle" />
            </Text>
            <Title extend={{ title: rules.title }} type={TitleTypes.H1_ITALIC_UPPERCASE} as={TitleTypes.H1}>
                <FormattedMessage id={title} />
            </Title>
        </div>
        <Image src={image} alt={title} />
    </DynamicContainer>
);

HeroSection.propTypes = {
    rules: PropTypes.shape().isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    reference: PropTypes.shape().isRequired,
};

export default HeroSection;
