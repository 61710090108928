import axios from 'axios';
import React from 'react';
import Config from '../../../config';

export const useFetchPosition = positionId => {
    const [success, setSuccess] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [inProgress, setInprogress] = React.useState(false);
    const [positionContent, setPositionContent] = React.useState(null);

    React.useEffect(() => {
        setInprogress(true);
        axios({
            method: 'get',
            url: Config.api.fetchPosition.replace(':id', positionId),
            headers: {
                Authorization: Config.api.publicATSToken,
            },
        })
            .then(function(response) {
                const {
                    data: {
                        payload: { description },
                    },
                } = response;

                setPositionContent(description);
                setSuccess(true);
                setError(null);
                setInprogress(false);
            })
            .catch(function(err) {
                setError(err);
                setSuccess(null);
                setInprogress(false);
            });
    }, [positionId]);

    return { inProgress, success, error, positionContent };
};
