import bulletImg from '../../../../../../static/images/bullet.svg';
import teamLeaderTopLeftDivider from '../../../../../../static/images/career/job-page/teamLeaderTopLeftDivider.svg';
import teamLeaderBottomLeftDivider from '../../../../../../static/images/career/job-page/teamLeaderBottomLeftDivider.svg';
import teamLeaderRightDivider from '../../../../../../static/images/career/job-page/teamLeaderRightDivider.svg';
import aboutUsDivider from '../../../../../../static/images/career/job-page/aboutUsDivider.svg';

export const extendMainContainer = {
    container: () => ({
        tabletLarge: {
            paddingBottom: 0,
            paddingTop: '2rem',
        },
    }),
};

export const extendContentContainer = {
    container: ({ heroHeight }) => ({
        marginTop: heroHeight / 2 + 60,

        tabletLarge: {
            marginTop: heroHeight,
        },

        desktopLarge: {
            marginTop: heroHeight / 2 + 150,
        },

        '@media (min-width: 1680px)': {
            marginTop: heroHeight / 2 + 50,
        },
    }),
};

export const extendTeamLeaderContainer = {
    container: ({ heroHeight, firstParagraphHeight }) => ({
        overflow: 'visible',
        marginTop: '3.75rem',

        tabletLarge: {
            marginTop: heroHeight - 170 + firstParagraphHeight,
        },

        desktopLarge: {
            marginTop: heroHeight - 170 + firstParagraphHeight,
        },
    }),
};

export const tlContentContainer = () => ({
    padding: '0 2rem 2rem 2rem',
    position: 'relative',
    backgroundImage: `url(${teamLeaderTopLeftDivider}), url(${teamLeaderBottomLeftDivider}), url(${teamLeaderRightDivider})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top 0.2rem left, bottom left, right top 40%',
});

export const extendTitle = {
    title: () => ({
        marginTop: 0,
    }),
};

export const container = ({ theme: { colors, transition } }) => ({
    '> p:first-of-type': {
        padding: '2rem',
        background: `url(${aboutUsDivider}) no-repeat left top`,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        marginBottom: '4rem',
    },

    '> p': {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
    },

    '> p > strong': {
        fontSize: '1.75rem',
        marginTop: '6.25rem',
        marginBottom: '2rem',
        display: 'block',
    },

    '& ul': {
        paddingLeft: '0.5rem',

        '& li': {
            listStyle: 'none',
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
            paddingLeft: '1.5rem',
            marginBottom: '1.5rem',
        },

        '& li::before': {
            content: `url(${bulletImg})`,
            position: 'absolute',
            display: 'inline-block',
            transform: 'translateY(-15%)',
            marginLeft: '-1.5rem',
            marginRight: '0.6875rem',
        },
    },

    '& a': {
        color: colors.blue,
        textDecorationColor: colors.blue,
        transition,
        transitionProperty: 'color',

        ':hover': {
            color: colors.fuchsia,
        },
    },

    '> figure > img': {
        maxWidth: '100%',
    },
});
