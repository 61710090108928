import React, { useState } from 'react';
import { useFelaEnhanced } from '@ackee/fela';

import { FormattedMessage, type MessageDescriptor } from 'react-intl';

import {
    Button,
    ButtonSize,
    ContainerType,
    DynamicContainer,
    Technologies,
    TechnologyCategory,
    Title,
    TitleTypes,
} from 'modules/ui';

import * as felaRules from './TechnologiesSection.rules';

export interface TechnologiesSectionProps {
    technologies: TechnologyCategory;
    title: MessageDescriptor['id'];
}

export const TechnologiesSection = ({ technologies, title }: TechnologiesSectionProps) => {
    const [expanded, setExpanded] = useState(false);
    const { styles } = useFelaEnhanced(felaRules, { expanded });

    const handleClick = () => {
        setExpanded(!expanded);
    };

    return (
        <DynamicContainer as={ContainerType.SECTION} isFluid extend={{ container: felaRules.extendContainer }}>
            <div className={styles.titleContainer}>
                {/* @ts-expect-error */}
                <Title type={TitleTypes.H5} as={TitleTypes.H3}>
                    <FormattedMessage id={title} />
                </Title>
                <Button extend={{ button: felaRules.extendButton }} size={ButtonSize.SMALL} onClick={handleClick}>
                    {expanded ? (
                        <FormattedMessage id="jobPage.technologies.button.hide" />
                    ) : (
                        <FormattedMessage id="jobPage.technologies.button.show" />
                    )}
                </Button>
            </div>
            <DynamicContainer<{ expanded: boolean }>
                expanded={expanded}
                extend={{ container: felaRules.extendTechnologiesContainer }}
            >
                <Technologies isCareer content={technologies} />
            </DynamicContainer>
        </DynamicContainer>
    );
};
