import type { TRuleWithTheme } from '@ackee/fela';

export const titleContainer: TRuleWithTheme = () => ({
    display: 'flex',
    marginBottom: '3.625rem',
});

export const extendContainer: TRuleWithTheme = () => ({
    paddingTop: 0,
    paddingBottom: '4rem',
    tabletLarge: {
        paddingBottom: 0,
        paddingTop: '4rem',
    },
});

export const extendButton: TRuleWithTheme = () => ({
    fontSize: '1.0625rem',
    lineHeight: '1.375rem',
    marginLeft: '1rem',
    textUnderlineOffset: '0.325rem',
    cursor: 'pointer',
});

export const extendTechnologiesContainer: TRuleWithTheme<{ expanded?: boolean }> = ({
    theme: { transition },
    expanded,
}) => ({
    transition: transition,
    maxHeight: expanded ? '100rem' : 0,
    opacity: expanded ? 1 : 0,

    overflow: 'visible',
    tabletLarge: {
        maxWidth: '70%',
    },
});
